import client from './requestclient';

const transactions = {
    propertyTransactions: async (id) => {
        return await client({URL_PATH: `fetchpropertiestransactions/${id}`, method: "GET"})
    },
    walletDetails: async (id) => {
        return await client({URL_PATH: `fetchwalletdetails/${id}`, method: "GET"})
    },
    walletDebitDetails: async (id) => {
        return await client({URL_PATH: `fetchwalletdebitdetails/${id}`, method: "GET"})
    },
    applyForWithdrawal: async (body) => {
        return await client({URL_PATH: `applyforwalletwithdrawal`, method: "POST", body: body})
    },
    safeWithdrawal: async (body) => {
        return await client({URL_PATH: `transferfromsmarttosafe`, method: "POST", body: body})
    },
    sendOtp: async (body) => {
        return await client({URL_PATH: `smartotptobank`, method: "POST", body: body})
    },
    validateOtp: async (body) => {
        return await client({URL_PATH: `validatesmartotp`, method: "POST", body: body})
    }
}

export default transactions;